import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit", "commentBox", "note", "categoryRow", "disclosure"]

  toggle(event) {
    if (event.target.checked) {
      this.submitTarget.value = "Submit Internal Comment";
      this.commentBoxTarget.classList.add('internal-comment');
      this.commentBoxTarget.classList.remove('public-comment');
      this.noteTarget.innerText = "Comment only visible to staff members with access to this report";
    } else {
      this.submitTarget.value = "Submit User Comment";
      this.commentBoxTarget.classList.remove('internal-comment');
      this.commentBoxTarget.classList.add('public-comment');
      this.noteTarget.innerText = "Comment will be sent to the user";
    }
  }

  filterCategory(event) {
    if (event.target.classList.contains('active')) {
      event.target.classList.remove('active');
      this.disclosureTargets.forEach(function(el) {
        el.style.display = 'block';
      });
    } else {
      let filterKey = event.params.label;
      this.categoryRowTargets.forEach(function(el) {
        el.classList.remove("active")
      });
      event.target.classList.add("active");
      this.disclosureTargets.forEach(function(el) {
        if (el.dataset.category === filterKey) {
          el.style.display = 'block';
        } else {
          el.style.display = 'none';
        }
      })
    };
  }
}
