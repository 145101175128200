import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["term", "enrollments", "spinner"];

  connect() {
  }

  change(event) {
    this.spinnerTarget.style.display = 'block';
    this.enrollmentsTarget.innerHTML = "";
    fetch(`/students/${event.params.userId}/enrollments?term_id=${this.termTarget.value}`, {
      method: 'GET'
    })
    .then((response) => response.text() )
    .then((data) => {
      this.enrollmentsTarget.innerHTML = data;
      this.spinnerTarget.style.display = 'none';
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

}
