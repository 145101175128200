import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["number", "input"];

  fill(event) {
    console.log(`Filling... `)
    console.log(event.params. index);
    console.log(event.params);
    this.numberTargets.forEach((target, index) => {
      target.classList.remove('nps-selected');
    });
    event.currentTarget.classList.add('nps-selected');
    if (this.hasInputTarget) {
      this.inputTarget.setAttribute('value', parseInt(event.params.index));
    }
  }
}
