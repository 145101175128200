import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';

export default class extends Controller {
  static values = {
    url: String,
    userId: String,
    allowClick: { type: Boolean, default: true },
    allowHover: { type: Boolean, default: true },
    compactDayOnly: { type: Boolean, default: false },
    initialView: { type: String, default: 'dayGridMonth' },
    eventClickDefault: { type: Boolean, default: true },
    eventViewDefault: { type: String, default: '/appointments'},
    fetchTeamworks: { type: Boolean, default: false },
    fetchOutlook: { type: Boolean, default: false },
    teamworksUrl: String,
    outlookUrl: String,
    teamworksIconUrl: String,
    outlookIconUrl: String
  }

  connect() {
    this.observeParentResize();

    var _this = this;
    var dataSources = [ this.urlValue ];
    var gridButtons = 'dayGridMonth,timeGridWeek,timeGridDay';
    if (this.fetchTeamworksValue) {
      dataSources.push(this.teamworksUrlValue);
    };
    if (this.fetchOutlookValue) {
      dataSources.push(this.outlookUrlValue);
    };
    if (this.compactDayOnlyValue) {
      this.defaultViewValue = 'dayGridDay';
      gridButtons = '';
    }
    if (this.initialViewValue) {
      this.defaultViewValue = this.initialViewValue;
    };
    this.calendar = new Calendar(this.element, {
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, momentPlugin ],
      eventDisplay: 'block',
      eventSources: dataSources,
      initialView: this.defaultViewValue,
      slotMinTime: "06:00:00",
      slotMaxTime: "22:00:00",
      height: "auto",
      slotDuration: '00:15:00',
      slotLabelInterval: "01:00",
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: gridButtons
      },
      allDaySlot: true,
      eventClick: function(info) {
        const isClassEvent = typeof info.event.extendedProps.isClassEvent !== "undefined" && info.event.extendedProps.isClassEvent;
        const isTwEvent = typeof info.event.extendedProps.isTwEvent !== "undefined" && info.event.extendedProps.isTwEvent;
        const isOutlookEvent = typeof info.event.extendedProps.isOutlookEvent !== "undefined" && info.event.extendedProps.isOutlookEvent;
        if (_this.allowClickValue && !isClassEvent && !isTwEvent && !isOutlookEvent) {
          $('#calendarSpinner').show();
          if (_this.eventClickDefaultValue) {
            Rails.ajax({
              url: `${_this.eventViewDefaultValue}/${info.event.id}.js?redirect=${encodeURIComponent(window.location.href)}`,
              type: "get",
              success: function(data) {
                $('#calendarSpinner').hide();
              },
              error: function(data) {}
            })
          } else {
            window.location.href = `/students/view_appointment?appointment_id=${info.event.id}`;
          }
        }
      },
      datesSet: function(info) {
        let iconSpan = $('.fc-toolbar-chunk:first-of-type');
        if (_this.fetchTeamworksValue) {
          if (iconSpan.find("span:contains('Teamworks Hub')").length === 0) {
            $('.fc-toolbar-chunk:first-of-type').append(`<span class='pl-2'><img src=${_this.teamworksIconUrlValue} width='20' /> Teamworks Hub</span>`);
          };
        }
        if (_this.fetchOutlookValue) {
          if (iconSpan.find("span:contains('Outlook')").length === 0) {
            $('.fc-toolbar-chunk:first-of-type').append(`<span class='pl-2'><img src=${_this.outlookIconUrlValue} width='20' /> Outlook</span>`);
          };
        }
      },
      eventDidMount: function(info) {
        if (info.event.extendedProps.icon) {
          $(info.el).find(".fc-event-title").append("<i class='fe fe-user-x'></i>")
        }
        if (info.event.extendedProps.zoom || info.event.extendedProps.teams) {
          $(info.el).find(".fc-event-title").append(" <i class='fe fe-video'></i>")
        }
        if (_this.allowHoverValue) {
          $(info.el).popover(
            {
              title: `${info.event.extendedProps.category} (${info.event.extendedProps.status}) ${info.event.extendedProps.isTwEvent ? `<img src=${_this.teamworksIconUrlValue} width='18' class='ml-1' />` : ""} ${info.event.extendedProps.isOutlookEvent ? `<img src=${_this.outlookIconUrlValue} width='18' class='ml-1' />` : ""}`,
              content: `${info.event.extendedProps.details}`,
              html: true,
              placement: "right",
              trigger: 'hover',
              container: 'body'
            }
          );
        }
      },
    });
    this.calendar.render();
  }

  disconnect() {
    // If the ResizeObserver is active, disconnect it to avoid memory leaks
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  // Sets up an observer to watch for size changes of the calendar's parent container
  observeParentResize() {
    const parentDiv = document.querySelector('.js--calendar-container');

    // Create a new ResizeObserver instance
    this.resizeObserver = new ResizeObserver(() => {
      // Re-render the calendar to adjust to the new size of its container
      this.calendar.render()
    });

    // Begin the observation
    this.resizeObserver.observe(parentDiv);
  }
}
