// noinspection JSUnresolvedReference

import { Controller } from '@hotwired/stimulus';
import UrlParameterMixin from "../mixins/url_parameter_mixin";

export default class StudentController extends Controller {
  connect() {
    // Get the appointment schedule id from the URL
    const appointmentScheduleId = this.getParameterByName('appointment_schedule_id');
    // Get the action status from the URL
    const added = this.getParameterByName('added');

    if (appointmentScheduleId && added) {
      this.#redirectToCleanUrl();
    }
  }

  #redirectToCleanUrl() {
    const url = new URL(window.location.href);

    url.searchParams.delete('added');
    url.searchParams.delete('appointment_schedule_id');

    window.history.replaceState(null, null, url.href);
  }
}

// Mixin the methods from UrlParameterMixin into this controller
Object.assign(StudentController.prototype, UrlParameterMixin);