import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /**
   * the `control` is an HTML checkbox which determines the content of the `container`
   */
  static targets = ["container", "control"]
  /**
   * the `url` pointing to the requested Rails controller method
   */
  static values = {
    url: String
  }

  /**
   * If the target elements are present, construct a new URL object and make the initial GET request to populate the
   * view.
   */
  initialize() {
     try {
      if (this.hasContainerTarget && this.hasControlTarget) {
        this.url = new URL(this.urlValue);
        this.updateView()
      } else if (this.hasControlTarget) {
        this.controlTarget.remove();
      }
    } catch (e) {
      console.error(e)
      if (this.hasControlTarget) {
        this.controlTarget.remove();
      }
    }
  }

  /**
   * Determine the toggle's value before appending params and executing the request.
   */
  toggle() {
    if (this.controlTarget.checked) {
      this.url.searchParams.append('show_active', '');
    } else {
      this.url.searchParams.delete('show_active');
    }
    this.updateView();
  }

  /**
   * Fetch the requested HTML and update the view.
   */
  updateView() {
    const fetchHeaders = new Headers({
      'Accept': 'text/javascript',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    })
    fetch(this.url, {
      method: 'GET',
      headers: fetchHeaders
    }).then((response) => response.text())
      .then((data) => {
        this.containerTarget.innerHTML = data;
      })
      .catch((error) => {
        console.error('Error', error);
      })
  }
}