import { Controller } from '@hotwired/stimulus';
import * as Toastr from 'toastr';

export default class extends Controller {
  static targets = ["add_block", "add_chapter", "template"]
  static values = { index: String }

  // Adds a new chapter to the form and places it after the last chapter
  add_chapter(event) {
    event.preventDefault()
    let content = this.templateTarget.innerHTML.replace(new RegExp(this.indexValue, 'g'), new Date().valueOf())

    // set the position for the new chapter
    const chapters = document.querySelectorAll('.chapter-position')
    const position = chapters.length
    content = content.replace('CHAPTER_POSITION', position)

    this.add_chapterTarget.insertAdjacentHTML('beforebegin', content)

    // add chapter to the list of chapters
    const chapterList = document.getElementById('chapter-list')
    const chapterItem = document.createElement('li')
    chapterItem.classList.add('list-group-item')
    chapterItem.innerHTML = `<a href="#chapter-${position}">Untitled Chapter</a>`
    chapterList.appendChild(chapterItem)

    // Init new content blocks with TinyMCE editors
    tinymce.init({
      selector: 'textarea.tinymce'
    });
  }

  // This is a workaround to trigger the click event on the hidden add_chapter button
  // which allows us to place the new chapter button anywhere
  new_chapter(event) {
    event.preventDefault()
    document.getElementById('add_chapter').click()
  }

  // Adds a new block in the desired chapter and places it after the last block
  add_block(event) {
    event.preventDefault()
    let content = this.templateTarget.innerHTML.replace(new RegExp(this.indexValue, 'g'), new Date().valueOf())

    // set the block type input value
    const blockType = event.target.dataset.type
    content = content.replace('BLOCK_TYPE', blockType)

    if (blockType === 'video') {
      content = content.replace('<div class="video-section" style="display:none">', '<div class="video-section">')
    } else {
      content = content.replace('<div class="content-section" style="display:none">', '<div class="content-section">')
    }

    // set the block position for the new block
    const chapter = event.target.closest('.chapter')
    const blocks = chapter.querySelectorAll('.block-position')
    const position = blocks.length
    content = content.replace('BLOCK_POSITION', position)

    // insert the new block
    this.add_blockTarget.insertAdjacentHTML('beforebegin', content)

    if (blockType === 'video') {
      this.element.querySelectorAll('.video-uploader').forEach((fileInput) => {
        fileInput.addEventListener('change', this.check_video_size)
      })
    }

    // Init new content blocks with TinyMCE editors
    tinymce.init({
      selector: 'textarea.tinymce'
    });
  }

  // Removes the desired chapter or block from the form
  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }

  // Check video file size before uploading
  check_video_size(event) {
    const file = event.target.files[0]
    if (file.size > 500000000) {
      Toastr.error('File is too big! Maximum size is 500MB.');
      event.target.value = ''
    }
  }
}
