// noinspection JSUnresolvedReference

import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';

export default class extends Controller {
  static targets = ["calendar", "startTime", "endTime", "startDate", "attendees", "submit", "courseId", "courseName"];
  static values = {
    url: String,
    userId: String,
    allowClick: { type: Boolean, default: true },
    allowHover: { type: Boolean, default: true },
    initialView: { type: String, default: 'dayGridMonth' },
    eventClickDefault: { type: Boolean, default: true },
    newAppointment: { type: Boolean, default: true },
    renderCalendar: { type: Boolean, default: false },
    teamworksIconUrl: String
  }

  connect() {
    if (this.renderCalendarValue) {
      this.renderCalendar();
    }
    this.initTimeSelectors();
    this.initDateSelector();
    this.initAttendees();
    this.initHost();

    if (this.hasCourseIdTarget) {
      const courseEl = document.getElementById('appointment_course_id');

      // If a course is selected on page load, this will copy the id to the courseIdTarget
      if (courseEl) {
        this.selectedCourse = courseEl.value;
      }
      this.courseIdTarget.value = this.selectedCourse ? this.selectedCourse : null;
    }
    
    this.validateSubmit();
    var _this = this;
    $('#new_appointment #appointment_room_number_id').on('change', function(e) {
      _this.checkForConflicts();
    });
  }

  initDateSelector() {
    var _this = this;
    this.appointmentDate = flatpickr(this.startDateTarget, {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      onOpen: function(selectedDates, dateStr, instance) {
        const curYearEl = instance.currentYearElement;
        const monthsDropdown = instance.monthsDropdownContainer;
        if (curYearEl.getAttribute('tabindex') === '-1') {
          curYearEl.setAttribute('tabindex', '0');
        }
        if (monthsDropdown.getAttribute('tabindex') === '-1') {
          monthsDropdown.setAttribute('tabindex', '0');
        }
      },
      onReady: function(selectedDates, dateStr, instance) {
        const inputField = instance._input;
        inputField.setAttribute('aria-label', 'Select date');
      },
      onChange: function(selectedDates, dateStr, instance) {
        _this.fullcalendar.gotoDate(selectedDates[0]);
        var selected_date = moment(selectedDates[0]);
        var placeholder = _this.fullcalendar.getEventById('appointmentPlaceholder');
        if (placeholder != null) {
          var start = moment().set('year', selected_date.year()).set('month', selected_date.month()).set('date', selected_date.date()).set('hour', placeholder.start.getHours()).set('minute', placeholder.start.getMinutes()).format();
          var end = moment().set('year', selected_date.year()).set('month', selected_date.month()).set('date', selected_date.date()).set('hour', placeholder.end.getHours()).set('minute', placeholder.end.getMinutes()).format();
          placeholder.setDates(start, end);
        }
        // availability
        _this.retrieveAvailability();
        _this.validateSubmit();
      }
    });
  }

  initAttendees() {
    var _this = this;
    var appointment_id = $('#appointmentId').text();
    const attendeeSelect = document.querySelector('#appointment_attendee_user_ids');
    const attendeeIds = [];
    if (attendeeSelect) {
      const attendeeSelected = attendeeSelect.selectedOptions;
      // Populate all existing student appointments by mimicking a select2:select event
      for (let i = 0; i < attendeeSelected.length; i++) {
        attendeeIds.push(attendeeSelected[i].attributes.value.nodeValue);
      }
    }

    if (attendeeIds.length > 0) {
      attendeeIds.forEach(id => {
        fetch(`/users/search?q=${id}&search_roles=student&id_search=true`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => {
            let customEvent = $.Event('select2:select', {
              params: {data: data.results[0]}
            });

            $('#appointment_attendee_user_ids').trigger(customEvent);
          })
          .catch(error => {
            console.error('Error:', error);
          });
      });
    }

    $("#appointment_attendee_user_ids").on('select2:select', function (e) {
      _this.fullcalendar.addEventSource({ url: `/users/${e.params.data.id}/schedule.json`, id: e.params.data.id });
      if (e.params.data.external_provider_id) {
        _this.fullcalendar.addEventSource({ url: `/users/${e.params.data.id}/schedule/teamworks.json`, id: `TW-${e.params.data.id}` });
      }
      _this.checkForConflicts();
    });

    $("#appointment_attendee_user_ids").on('select2:unselect', function (e) {
      var eventSource = _this.fullcalendar.getEventSourceById(e.params.data.id);
      var twEventSource = _this.fullcalendar.getEventSourceById(`TW-${e.params.data.id}`);

      eventSource.remove();

      if (twEventSource) {
        twEventSource.remove();
      }
      
      _this.checkForConflicts();
    });
    // if (attendee_ids != null && attendee_ids.length) {
    //   attendee_ids.forEach(function(id) {
    //     _this.fullcalendar.addEventSource({ url: `/users/${id}/schedule.json?exclude_ids=${appointment_id}`, id: id });
    //     if (e.params.data.external_provider_id) {
    //       _this.fullcalendar.addEventSource({ url: `/users/${e.params.data.id}/schedule/teamworks.json`, id: `TW-${e.params.data.id}` });
    //     }
    //   });
    //   calendar.rerenderEvents()
    // };
  }

  initTimeSelectors() {
    var _this = this;
    this.appointmentStart = flatpickr(this.startTimeTarget, {
      enableTime: true,
      slotMinTime: "06:00:00",
      slotMaxTime: "22:00:00",
      noCalendar: true,
      time_24hr: false,
      dateFormat: "h:i K",
      altInput: true,
      altFormat: "G:i K",
      onChange: function(selectedDates, dateStr, instance) {
        var selected_date = moment(selectedDates[0]);
        var placeholder = _this.fullcalendar.getEventById('appointmentPlaceholder');
        if (placeholder != null) {
          var start = moment(placeholder.start).set('hour', selected_date.hour()).set('minute', selected_date.minutes()).format();
          placeholder.setStart(start);
        };
        _this.checkForConflicts();
        _this.validateSubmit();
      }
    });
    this.appointmentEnd = flatpickr(this.endTimeTarget, {
      enableTime: true,
      noCalendar: true,
      time_24hr: false,
      dateFormat: "h:i K",
      altInput: true,
      altFormat: "G:i K",
      onChange: function(selectedDates, dateStr, instance) {
        var selected_date = moment(selectedDates[0]);
        var placeholder = _this.fullcalendar.getEventById('appointmentPlaceholder');
        if (placeholder != null) {
          var end = moment(placeholder.end).set('hour', selected_date.hour()).set('minute', selected_date.minutes()).format();
          placeholder.setEnd(end);
        };
        _this.checkForConflicts();
        _this.validateSubmit();
      }
    });
  }

  renderCalendar() {
    var _this = this;
    this.fullcalendar = new Calendar(this.calendarTarget, {
      plugins: [ timeGridPlugin, interactionPlugin ],
      eventDisplay: 'block',
      initialView: 'timeGridWeek',
      initialDate: moment(this.startDateTarget.value).toISOString(),
      selectable: this.newAppointmentValue,
      allDaySlot: false,
      slotMinTime: "06:00:00",
      slotMaxTime: "22:00:00",
      scrollTime: '08:30:00',
      height: "auto",
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: ''
      },
      datesSet: function(info) {
        var existing_host_id = $('#appointment_host_user_id').val();
        if (existing_host_id.length) {
          $.ajax("/users/" + existing_host_id + `/availability?start=${info.view.currentStart}&end=${info.view.currentEnd}`, {
            dataType: "json"
          }).done(function(data) {
            _this.fullcalendar.setOption('businessHours', data);
          });
        };
      },
      eventDidMount: function(info) {
        const { extendedProps } = info.event;
        const popoverCategory = extendedProps.category ?? null;
        const popoverStatus = extendedProps.status ?? null;
        const popoverContent = extendedProps.details ?? null;
        const initials = extendedProps.initials ? ` (${extendedProps.initials})` : '';

        $(info.el).find('.fc-event-title').append(initials);

        if (extendedProps.category) {
          const twEventIcon = extendedProps.isTwEvent ? `<img src=${_this.teamworksIconUrlValue} width='18' class='ml-1' />` : "";

          $(info.el).popover({
            title: `${popoverCategory} (${popoverStatus}) ${twEventIcon}`,
            content: popoverContent,
            html: true,
            placement: 'right',
            trigger: 'hover',
            container: 'body'
          });
        }
      },
      eventDrop: function(info) {
        _this.appointmentDate.setDate(moment(info.event.start).format('YYYY-MM-DD'), false);
        _this.appointmentStart.setDate(moment(info.event.start).format('hh:mm A'), false);
        _this.appointmentEnd.setDate(moment(info.event.end).format('hh:mm A'), false);
        _this.retrieveAvailability();
        _this.validateSubmit();
      },
      loading: function(isLoading) {
        if (isLoading) {
          $('.fc-toolbar-chunk:first-of-type').append(`<div class='spinner-border text-primary'></div>`);
        } else {
          $('.fc-toolbar-chunk:first-of-type .spinner-border').remove();
        };
      },
      eventResize: function(info) {
        _this.appointmentDate.setDate(moment(info.event.start).format('YYYY-MM-DD'), false);
        _this.appointmentStart.setDate(moment(info.event.start).format('hh:mm A'), false);
        _this.appointmentEnd.setDate(moment(info.event.end).format('hh:mm A'), false);
      },
      select: function(selectionInfo) {
        var placeholder = _this.fullcalendar.getEventById('appointmentPlaceholder');
        if (placeholder == null) {
          _this.appointmentDate.setDate(selectionInfo.start);
          _this.appointmentStart.setDate(moment(selectionInfo.start).format('hh:mm A'), false);
          _this.appointmentEnd.setDate(moment(selectionInfo.end).format('hh:mm A'), false);
          $(".fc-highlight").css("background", "green");
          _this.fullcalendar.addEvent({
            id: 'appointmentPlaceholder',
            title: "Placeholder",
            start: selectionInfo.start,
            end: selectionInfo.end,
            editable: true,
            backgroundColor: '#b8e0b3',
            className: 'placeholder-pulse',
            borderColor: '#5ea356',
            textColor: 'black',
          });
        } else {
          _this.appointmentDate.setDate(selectionInfo.start);
          _this.appointmentStart.setDate(moment(selectionInfo.start).format('hh:mm A'), false);
          _this.appointmentEnd.setDate(moment(selectionInfo.end).format('hh:mm A'), false);
          placeholder.setStart(selectionInfo.start);
          placeholder.setEnd(selectionInfo.end);
        };
        _this.retrieveAvailability();
        _this.validateSubmit();
      },
      selectConstraint: {
        startTime: '06:00', // a start time (10am in this example)
        endTime: '22:00', // an end time (6pm in this example)
      },
    });
    this.fullcalendar.render();

    var fromDate = this.startDateTarget.value == '' ? moment() : moment(this.startDateTarget.value, 'YYYY-MM-DD')
    var fromTime = this.startTimeTarget.value == '' ? moment().set('minute', 0) : moment(this.startTimeTarget.value, 'LT')
    var toTime = this.endTimeTarget.value == '' ? moment().set('minute', 0).add(1, 'hours') : moment(this.endTimeTarget.value, 'LT')

    this.fullcalendar.addEvent({
      id: 'appointmentPlaceholder',
      title: "Placeholder",
      start: moment().set('year', fromDate.year()).set('month', fromDate.month()).set('date', fromDate.date()).set('hour', fromTime.hour()).set('minute', fromTime.minute()).format(),
      end: moment().set('year', fromDate.year()).set('month', fromDate.month()).set('date', fromDate.date()).set('hour', toTime.hour()).set('minute', toTime.minute()).format(),
      editable: true,
      backgroundColor: '#b8e0b3',
      className: 'placeholder-pulse',
      borderColor: '#5ea356',
      textColor: 'black',
    });
  }

  initHost() {
    const userIds = [];
    const appointmentId = document.querySelector("#appointmentId").textContent;
    const appointmentHosts = document.querySelectorAll('#appointmentHosts tr')
    const startDate = document.querySelector('#appointment_scheduled_start').value;
    const locationId = document.querySelector('#appointment_location_id').value;
    const fromDate = moment(this.startDate, 'YYYY-MM-DD');
    const fromTime = moment(document.querySelector('#appointment_start_time').value, 'LT');
    const toTime = moment(document.querySelector('#appointment_end_time').value, 'LT');
    const selectedHostId = document.querySelector('#appointmentHosts tr.table-secondary');

    // Check for the existence of the course select before checking its value
    const courseElChecked = document.querySelector('#appointment_course_id option:checked');
    const courseId = courseElChecked ? courseElChecked.value : null;

    appointmentHosts.forEach(row => {
      userIds.push(row.getAttribute('id'));
    });

    if (userIds.length > 0) {
      this.fetchAvailability(userIds, courseId, locationId, startDate);
    }

    if (selectedHostId !== null) {
      const hostId = selectedHostId.getAttribute('id');
      var hostOutlookConnected = selectedHostId.dataset.outlookConnected === "true";

      if (appointmentId) {
        this.fullcalendar.addEventSource({
          url: `/users/${hostId}/schedule.json?exclude_ids=${appointmentId}`,
          id: hostId
        });
      } else {
        // Handles event sources on page load when appointmentId is undefined. Such as deep linking
        // with a pre-selected user from a Profile page
        this.fullcalendar.addEventSource({
          url: `/users/${hostId}/schedule.json`,
          id: hostId
        });
      };
      if (hostOutlookConnected) {
        this.fullcalendar.addEventSource({ url: `/users/${hostId}/schedule/outlook.json`, id: `Outlook-${hostId}` });
      };
    }

    this.fullcalendar.addEvent({
      id: 'appointmentPlaceholder',
      title: 'Placeholder',
      start: moment().set('year', fromDate.year()).set('month', fromDate.month()).set('date', fromDate.date()).set('hour', fromTime.hour()).set('minute', fromTime.minute()).format(),
      end: moment().set('year', fromDate.year()).set('month', fromDate.month()).set('date', fromDate.date()).set('hour', toTime.hour()).set('minute', toTime.minute()).format(),
      editable: true,
      backgroundColor: '#b8e0b3',
      className: 'placeholder-pulse',
      borderColor: '#5ea356',
      textColor: 'black',
    });
  }

  fetchAvailability(users, course, location, start) {
    const paramsObj = {
      user_ids: users,
      by_course_id: course,
      by_location_id: location,
      by_start_date: start,
    };
    fetch(`/appointments/retrieve_availability`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(paramsObj),
    })
      .then(response => response.json())
      .then(data => {
        // Update the hidden courseName field with the retrieved course_name
        if (this.hasCourseNameTarget) { this.courseNameTarget.value = data.course_name; }
        this.updateUserAvailability(data)
      })
      .catch(error => {
        console.error('Error:', error);
      })
  }

  selectHost(event) {
    var _this = this;
    var calendar_view = this.fullcalendar.view;
    var user_id = event.target.closest("tr").id;
    var outlookConnected = event.target.closest("tr").dataset.outlookConnected === "true";
    if ($('#appointmentHosts .table-secondary').length) {
      var old_id = $('#appointmentHosts .table-secondary').attr('id');
      var oldOutlookConnected = $('#appointmentHosts .table-secondary').data("outlook-connected") === true;
      var oldEventSource = _this.fullcalendar.getEventSourceById(old_id);
      oldEventSource.remove();
      if (oldOutlookConnected) {
        var oldOutlookEventSource = _this.fullcalendar.getEventSourceById(`Outlook-${old_id}`);
        oldOutlookEventSource.remove();
      }
    };
    $('#appointmentHosts tr').removeClass('table-secondary');
    if (old_id === user_id) {
      $('#appointment_host_user_id').val('');
      _this.fullcalendar.setOption('businessHours', false);
      //checkForConflicts();
    } else {
      event.target.closest("tr").classList.add('table-secondary');
      $('#appointment_host_user_id').val(user_id);
      $.ajax("/users/" + event.target.closest("tr").id + `/availability?start=${calendar_view.currentStart}&end=${calendar_view.currentEnd}`, {
        dataType: "json"
      }).done(function(data) {
        _this.fullcalendar.setOption('businessHours', data);
        if (data[0] && data[0].defaultRoom && data[0].defaultRoom.length > 0) {
          if ($('#appointment_room_number_id').length) {
            $('#appointment_room_number_id').val(data[0].defaultRoom).change();
          }
        };
      });
      _this.fullcalendar.addEventSource({ url: `/users/${user_id}/schedule.json`, id: user_id });
      if (outlookConnected) {
        _this.fullcalendar.addEventSource({ url: `/users/${user_id}/schedule/outlook.json`, id: `Outlook-${user_id}` });
      };
    };
  }

  checkForConflicts() {
    var start_date = $('#appointment_scheduled_start').val();
    var start_time = $('#appointment_start_time').val();
    var end_time = $('#appointment_end_time').val();
    var room_number = $('#appointment_room_number_id').val();
    var host_user_id = $('#appointment_host_user_id').val();
    var attendee_ids = $('#appointment_attendee_user_ids').val();
    var exclude_id = $('#appointmentId').text();
    var room_numbers = $("#appointment_room_number_id option").map(function() { return $(this).val(); }).get();
    $('#conflictMessages').hide();
    if (start_date && start_time && end_time) {
      Rails.ajax({
        url: "/appointments/validate_conflicts",
        type: "post",
        data: `start_date=${start_date}&start_time=${start_time}&end_time=${end_time}&room_number_id=${room_number}&host_user_id=${host_user_id}&attendee_user_ids=${attendee_ids}&exclude_id=${exclude_id}&room_numbers=${room_numbers}`,
        success: function(data) {
          $('#conflictMessages').show();
          if (data.conflicts === 0) {
            $('#conflictMessages button').popover('dispose');
            $('#conflictMessages button').removeClass('btn-danger').addClass('btn-success').html("<i class='fe fe-check-circle'></i>")
          } else {
            $('#conflictMessages button').removeClass('btn-success').addClass('btn-danger').html(`<i class='fe fe-alert-triangle'></i> ${data.conflicts}`);
            $('#conflictMessages button').popover('dispose');
            $('#conflictMessages button').popover(
                {
                  title: `Conflicts`,
                  content: `<ul>${data.conflict_messages.join('')}</ul>`,
                  html: true,
                  placement: "right",
                  trigger: 'hover',
                  container: 'body'
                }
            );
          };
          if (data.room_number_validation == true) {
            $("#appointment_room_number_id > option").each(function() {
              if (this.text === '') { return true; }
              var opt = $(`#appointment_room_number_id option[value='${this.value}']`)
              $(opt).attr('data-content', `${this.text} <span class='text-secondary' style='color:green!important'>●</span>`);
            });
            data.unavailable_rooms.forEach(function (element) {
              var opt = $(`#appointment_room_number_id option[value='${element}']`)
              var optText = $(opt).text();
              $(opt).attr('data-content', `${optText} <span class='text-secondary' style='color:red!important'>●</span>`);
            });
            $('#appointment_room_number_id').selectpicker('refresh');
          };
        },
        error: function(data) {}
      });
    } else {
      $('#conflictMessages').hide();
    }
  }

  updateUserAvailability(data) {
    $('#appointmentHosts tr .available-status span').removeClass('badge-soft-success').addClass('badge-soft-secondary');
    $('#appointmentHosts tr .max-hours-remaining').removeClass('over-max-hours')
    $.each(data.available, function (i, val){
      $(`#appointmentHosts tr[id=${val}] .available-status span`).removeClass('badge-soft-secondary');
      $(`#appointmentHosts tr[id=${val}] .available-status span`).addClass('badge-soft-success');
    });
    $.each(data.hours, function (i, val){
      $(`#appointmentHosts tr[id=${val.id}] .max-hours-remaining`).text(val.remaining);
      if (val.remaining < 0) {
        $(`#appointmentHosts tr[id=${val.id}] .max-hours-remaining`).addClass('over-max-hours');
      };
    });
    $('#appointmentHosts tr .available-status .badge-soft-success').parent().parent().prependTo('#appointmentHosts tbody');
  }

  validateSubmit() {
    if (this.startDateTarget.value && this.startTimeTarget.value && this.endTimeTarget.value) {
      var startTime = moment(this.startTimeTarget.value, 'h:mm a');
      var endTime = moment(this.endTimeTarget.value, 'h:mm a');
      this.submitTarget.disabled = endTime.isBefore(startTime);
    } else {
      this.submitTarget.disabled = true;
    }
  }

  retrieveAvailability() {
    var _this = this;
    var user_ids = [];
    var appt_course_id = $("#appointment_course_id").val();
    var location_id = $("#appointment_location_id").val();
    var start_date = $("#appointment_scheduled_start").val();
    $('.fc-toolbar-chunk:first-of-type').append(`<div class='spinner-border text-primary'></div>`);
    $('#appointmentHosts tr').each(function(){
      var $this = $(this);
      user_ids.push([ $this.attr('id') ]);
    });

    let ajax_params = `user_ids=${user_ids}&by_location_id=${location_id}&by_start_date=${start_date}`;
    // This is conditionally wrapped because this method can be called by course-less appointments.
    if (_this.hasCourseIdTarget) {

      // This condition determines the behavior of the AJAX request:
      // 
      // If the first condition is met, it means the course hasn't been updated yet, 
      // and the AJAX request should submit the course name instead. 
      //
      // Otherwise, if the second condition is met, the new course_id will be sent, 
      // and a new course name will be retrieved and the hidden target field will be updated with the new course_id.
      if (appt_course_id === _this.courseIdTarget.value) {
        ajax_params += `&by_course_id=&by_course_name=${_this.courseNameTarget.value}`;
      } else {
        ajax_params += `&by_course_id=${appt_course_id}&by_course_name=`;
        _this.courseIdTarget.value = appt_course_id;
      }
    }

    Rails.ajax({
      url: "/appointments/retrieve_availability",
      type: "post",
      data: ajax_params,
      success: function(data) {
        // Update the hidden courseName field with the retrieved course_name
        if (_this.hasCourseNameTarget) { _this.courseNameTarget.value = data.course_name; }
        _this.updateUserAvailability(data);
        $('.fc-toolbar-chunk:first-of-type .spinner-border').remove();
      },
      error: function(data) {}
    });
    this.checkForConflicts()
  }
}
