import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import DromoUploader from "dromo-uploader-js";

export default class extends Controller {
  static targets = ['upload']
  static values = {
    frontendKey: String,
    schemaKey: String,
    importCategory: String,
    redirectUrl: String,
    developmentMode: Boolean
  }

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').content;
    this.dromo = new DromoUploader(this.frontendKeyValue, this.schemaKeyValue);
    this.dromo.setDevelopmentMode(this.developmentModeValue);
    this.dromo.onResults((data, metadata) => {
      fetch(`/shared/file_imports/dromo`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.token
        },
        body: JSON.stringify({
          file_category: this.importCategoryValue,
          import_data: data
        })
      })
      .then((response) => response)
      .then((data) => {
        window.location = this.redirectUrlValue;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    });
  }

  upload() {
    this.dromo.open()
  }

  
}
