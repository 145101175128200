// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "links", "taskList", "send", "taskItem", "name", "dueDate" ]

  connect() {
  }

  sendReminders(event) {
    console.log("sending..")
    var ids = [];
    var i, ids, id, len, user, users;
    var users = $("input[name='usersSelect']:checked");
    var message = $('#reminder_message').val()
    for (i = 0, len = users.length; i < len; i++) {
      user = users[i];
      id = $(user).data('id')
      ids.push(id)
    }
    console.log(ids)
    this.postReminders(ids, message)
  }

  postReminders(ids, message) {
    fetch(`/tasks/send_reminders`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({task_ids: ids, message:  message}),
    })
    .then((response) => response)
    .then((data) => {
      $('#reminderSentSuccess').show();
      $('#bulkActions').hide();
      $("input[name='usersSelect']:checked").prop("checked", false);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    let taskList = document.getElementById("taskListItems");
    let prevHtml = taskList.innerHTML
    taskList.innerHTML = xhr.response + prevHtml;
    this.nameTarget.value = "";
    this.dueDateTarget.value = "";
    let calendar = flatpickr(this.dueDateTarget, {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "Y-m-d G:i K",
      onOpen: function(selectedDates, dateStr, instance) {
        const curYearEl = instance.currentYearElement;
        const monthsDropdown = instance.monthsDropdownContainer;
        if (curYearEl.getAttribute('tabindex') === '-1') {
          curYearEl.setAttribute('tabindex', '0');
        }
        if (monthsDropdown.getAttribute('tabindex') === '-1') {
          monthsDropdown.setAttribute('tabindex', '0');
        }
      },
      onReady: function(selectedDates, dateStr, instance) {
        const inputField = instance._input;
        inputField.setAttribute('aria-label', 'Select date');
      },
    });
    calendar.clear(); // go a month ahead
    this.nameTarget.focus();
  }

  markComplete() {
  }
}
