import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'upcomingSelect' ]

  connect() {
    this.upcomingSelectTarget.addEventListener('change', this.upcomingSelectTargetChanged.bind(this))
  }

  upcomingSelectTargetChanged() {
    if (this.upcomingSelectTarget.value === 'All Future Appointments') {
      document.querySelectorAll('.upcoming-options').forEach((element) => {
        element.style.display = 'block'
      })
    } else {
      document.querySelectorAll('.upcoming-options').forEach((element) => {
        element.style.display = 'none'
      })
    }
  }

}
