import { Controller } from '@hotwired/stimulus'

export default class AppendParamsController extends Controller {
  static targets = ['value', 'button']

  connect() {}

  apply(event) {
    event.preventDefault();
    let selectedOptions = [];
    const checkboxes = this.valueTargets.filter(checkbox => checkbox.checked);

    checkboxes.forEach((target) => {
      selectedOptions.push(target.value);
    });

    if (selectedOptions.length > 0) {
      const url = new URL(this.buttonTarget.href);
      url.searchParams.set('form_section_ids', selectedOptions.join(','));
      this.buttonTarget.href = url.href;
    }

    window.open(this.buttonTarget.href, '_blank');
    window.location.reload();
  }
}
