import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "name", "categories","result", "waiting", "message", "form" ]

  connect() {
    console.log("connected")
  }

  toggle(event) {
    $('#availableSlots').empty();
    $('#confirmStudentSchedule').hide();
    if (event.target.value) {
      $( ".region" ).each(function( index ) {
        $( this ).show()
      });
      //$('input[id="appointment_appointment_reason_id"]').val(event.target.value);
    } else {
      $( ".region" ).each(function( index ) {
        $( this ).hide()
      });
      //$('input[id="appointment_appointment_reason_id"]').val('');
    }
  }

}
