import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  viewBlueprintSummary(event) {
    let blueprint_id = document.getElementById("blueprint_id");
    window.location.href = `/pathways/blueprints/${blueprint_id.value}/summary`;
  }

  pastMonth() {
    var startDateField = document.querySelector('input[name="by_date[start_date]"]');
    var endDateField = document.querySelector('input[name="by_date[end_date]"]');
    var startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    var endDate = new Date();
    startDateField.value = startDate.toISOString();
    endDateField.value = endDate.toISOString();
    $('#by_date_start_date').val(startDateField.value.format('YYYY-MM-DD'))
    $('#by_date_end_date').val(endDateField.value.format('YYYY-MM-DD'))
    const fp = document.querySelector("#date-range")._flatpickr;
    fp.setDate([startDateField.value.format('YYYY-MM-DD'), endDateField.value.format('YYYY-MM-DD')], true);

    $('.btn-group-toggle a').removeClass('btn-secondary');
    $('#month-btn').removeClass('btn-light');
    $('#year-btn').addClass('btn-light');
    $('#custom-btn').addClass('btn-light');
    $('#month-btn').addClass('btn-secondary');

    $('#date-select').hide();

    $('#duration').val('month');
  }

  pastYear() {
    var startDateField = document.querySelector('input[name="by_date[start_date]"]');
    var endDateField = document.querySelector('input[name="by_date[end_date]"]');
    var startDate = new Date();
    startDate.setYear(startDate.getFullYear() - 1);
    var endDate = new Date();
    startDateField.value = startDate.toISOString();
    endDateField.value = endDate.toISOString();
    $('#date-range').val(startDateField.value.format('YYYY-MM-DD'))
    $('#date-range').val(endDateField.value.format('YYYY-MM-DD'))
    const fp = document.querySelector("#date-range")._flatpickr;
    fp.setDate([startDateField.value.format('YYYY-MM-DD'), endDateField.value.format('YYYY-MM-DD')], true);

    $('.btn-group-toggle a').removeClass('btn-secondary');
    $('#year-btn').removeClass('btn-light');
    $('#month-btn').addClass('btn-light');
    $('#custom-btn').addClass('btn-light');
    $('#year-btn').addClass('btn-secondary');

    $('#date-select').hide();

    $('#duration').val('year');
  }

  custom() {
    $('.btn-group-toggle a').removeClass('btn-secondary');
    $('#custom-btn').removeClass('btn-light');
    $('#month-btn').addClass('btn-light');
    $('#year-btn').addClass('btn-light');
    $('#custom-btn').addClass('btn-secondary');

    $('#date-select').show();

    $('#duration').val('custom');
  }
}
