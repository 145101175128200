import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  static targets = []

  connect() {
    const input = document.querySelector("#phone");
    intlTelInput(input, {
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
      initialCountry: "us",
      nationalMode: true,
      hiddenInput: "primary_phone_number",
      autoPlaceholder: "aggressive"
    });
  }
}
