import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["subject", "message"]

  connect() {
  }

  insert(event) {
    event.preventDefault();
    let templateId = event.target.dataset.id;

    fetch(`/message_templates/${templateId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((data) => {
      this.messageTarget.value = data.message_body;
      if (this.hasSubjectTarget) {
        this.subjectTarget.value = data.subject;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }


}
