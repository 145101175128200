import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { delay: Number }
  
  connect() {
    this.hideAfterDelay();
  }

  hideAfterDelay() {
    const delay = this.delayValue || 1500; // Default to 1.5 seconds
    setTimeout(() => {
      this.element.style.transition = 'opacity 0.5s ease';
      this.element.style.opacity = '0';
    }, delay);
  }
}
