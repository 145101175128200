import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['squares'];
  static values = {
    args: Array
  }

  connect() {
    this.max_r = this.argsValue[this.argsValue.length - 1].max_r;
    this.argsValueMap = {};
    this.populateMap();
    this.iterate();
    $('[data-toggle="tooltip"]').tooltip();
  }

  iterate() {
    let fragment = document.createDocumentFragment();
    for (let x = 0; x < 24; x++) {
      for (let y = 0; y < 7; y++) {
        let key = `${x},${y}`;
        const li = document.createElement('li');
        const dataPoint = this.argsValueMap[key];

        li.setAttribute('data-x', x);
        li.setAttribute('data-y', y);

        if (dataPoint && dataPoint.r > 0) {
          let percentile = (dataPoint.r / this.max_r) * 100;
          let alpha = Math.ceil(percentile / 20) * 0.20;

          li.style.backgroundColor = `rgba(102,162,244,${alpha})`;
          li.style.border = 'none';

          this.insertTooltip(li, dataPoint.r);
        }
        fragment.appendChild(li);
      }
    }
    this.squaresTarget.appendChild(fragment);
  }

  populateMap() {
    this.argsValue.forEach(item => {
      let key = `${item.x},${item.y}`;
      this.argsValueMap[key] = item;
    })
  }

  insertTooltip(el, criteriaCount) {
    let tipText = criteriaCount === 1 ? 'check-in' : 'check-ins';
     el.setAttribute('data-toggle', 'tooltip');
     el.setAttribute('data-title', `${criteriaCount} ${tipText}`);
  }
}