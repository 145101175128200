import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['passwordField', 'icon'];

  togglePassword() {
    const passwordField = this.passwordFieldTarget;
    const icon = this.iconTarget;

    if (passwordField.type === 'password') {
      passwordField.type = 'text';
      icon.classList.replace('fe-eye', 'fe-eye-off');
    } else {
      passwordField.type = 'password';
      icon.classList.replace('fe-eye-off', 'fe-eye');
    }
  }
}