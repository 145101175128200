// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { Autocomplete } from 'stimulus-autocomplete'
import RailsNestedForm from '@stimulus-components/rails-nested-form'

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register('autocomplete', Autocomplete)
application.register('nested-form', RailsNestedForm)
