import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['addFields', 'removeFields', 'insertionPoint', 'placeholder']
  connect() {
    this.#noLinks();
  }

  handleAdd(event) {
    // Remove placeholder element after the first form block is added
    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.style.display = 'none';
    }

    if (!this.hasAddFieldsTarget || !event) return;
    event.preventDefault();

    let time = new Date().getTime();
    let linkId = this.addFieldsTarget.dataset.id;
    let regexp = linkId ? new RegExp(linkId, 'g') : null;
    let newFields = regexp ? this.addFieldsTarget.dataset.fields.replace(regexp, time) : null;

    // might not need the insertionPoint if the element is properly sent to draggable_form
    newFields ? this.dispatch('sendNestedElement', { detail: { content: {target: event.target, data: newFields} } }) : null; // draggable_form#createElement()
  }

  handleRemove(event) {
    if (!this.hasRemoveFieldsTarget || !event) return;

    event.preventDefault();

    let fieldParent = event.target.closest('.draggable-item');
    let deleteField = fieldParent ? fieldParent.querySelector('input[type="hidden"]') : null;

    // Prepare the removed element for destruction
    if (deleteField) {
      deleteField.value = 1;
      fieldParent.setAttribute('marked-for-deletion', true)
      fieldParent.style.display = 'none';
    }
  }

  #noLinks() {
    if (!this.hasAddFieldsTarget) return;
  }
}