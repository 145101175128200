import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["zoomContainer", "startUrl", "joinUrl"];
  static values = {
    appointment: String
  };

  connect() {
    this.loadZoomData();
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        // The page is no longer visible.
        this.clearZoomData();
      } else {
        // The page is now visible.
        this.loadZoomData();
      }
    })
  }

  // Clear the URLs when the viewport loses visibility
  clearZoomData() {
    if (this.hasStartUrlTarget) {
      this.startUrlTarget.href = '#';
    }
    if (this.hasJoinUrlTarget) {
      this.joinUrlTarget.href = '#';
    }
  }

  // Fetch a JSON response containing the requested URLs
  loadZoomData() {
    fetch(`/appointments/${this.appointmentValue}/zoom_data`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        return response.json();
      })
      .then(data => {

        if (this.hasStartUrlTarget) {
          this.startUrlTarget.href = data.zoom_start_url;
        }
        if (this.hasJoinUrlTarget) {
          this.joinUrlTarget.href = data.zoom_join_url;
        }
      });
  }
}