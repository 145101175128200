// noinspection JSUnresolvedReference

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'bulkActions', 'checkBox' ];

  /**
   * `this.selectRow()`, called on `connect()`, ensures proper row count if boxes are checked on page load, etc.
   */
  connect() {
    this.selectRow();
  }

  /**
   * Store all selected checkboxes in an array, `checkedBoxes`. If the array is not empty, show the bulk options
   * dropdown button.
   *
   * The array, regardless of length, can be supplied to other Stimulus controllers via a special data-action attribute
   * amended to this controller's parent element.
   *
   * FYI: Using `dispatch` has been deprecated in Stimulus v3.2.0 in favor of the new Outlets API.
   */
  selectRow() {
    const checkedBoxes = this.checkBoxTargets.filter(checkbox => checkbox.checked);

    // When there are multiple bulk actions,
    //   we need to iterate over each one and set its display property,
    //   and use class for bulkActions instead of id.
    // Otherwise when there is only one, we can just use id.
    this.bulkActionsTargets.forEach(bulkAction => {
      bulkAction.style.display = checkedBoxes.length > 0 ? 'block' : 'none';
    });

    this.dispatch('sendRows', { detail: { content: checkedBoxes } });
  }

  /**
   * Prevents the select-all checkbox from simply toggling per-row checkbox values, followed by a call to
   * `this.selectRow()`
   *
   * @param event
   */
  selectAll(event) {
    const isChecked = event.target.checked;

    this.checkBoxTargets.forEach(checkbox => {
      checkbox.checked = isChecked;
    });
    this.selectRow();
  }
}