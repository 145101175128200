import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "query", "results", "pinnedResult", "result", "dropdownContainer", "dropdown", "myTags", "advancedSearch", "handoff" ]
  static values = { url: String }

  connect() {
    // Initialize state variables
    this.dropdownVisible = false;
    this.useBackdrop = false;

    // Initialize jQuery dropdown for the query input
    $(this.queryTarget).dropdown();

    // Add event listeners
    this.element.addEventListener('toggle-search-dropdown', this.toggleSearchDropdown.bind(this));
    this.debouncedFetchResults = debounce(this.fetchResults.bind(this), 3000);
    this.queryTarget.addEventListener('input', () => this.debouncedFetchResults());
    this.isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

    // Create and set up the backdrop for the handoff feature
    this.createBackdrop();

    // Set the placeholder text for the query input based on the user's platform
    this.setInputPlaceholder();

    // Listen for keydown events for keyboard navigation and shortcuts
    document.addEventListener('keydown', this.handleKeyDown.bind(this));

    // Handle dropdown show/hide events
    $(this.dropdownContainerTarget).on('shown.bs.dropdown', () => {
      this.dropdownVisible = true
      if (this.useBackdrop) {
        this.showBackdrop();
      }
    });

    $(this.dropdownContainerTarget).on('hidden.bs.dropdown', () => {
      this.dropdownVisible = false
      if (this.useBackdrop) {
        this.hideBackdrop();
        this.useBackdrop = false;
      }
    });

    // Handle clicks outside the dropdown to close it
    document.addEventListener('click', this.handleDocumentClick.bind(this));
  }

  disconnect() {
    // Clean up event listeners and reset state when controller is disconnected
    this.reset();
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleDocumentClick);
    this.element.removeEventListener('toggle-search-dropdown', this.toggleSearchDropdown.bind(this));
  }

  /**
   * Close the dropdown if a click occurs outside of it while it's open
   * @param event
   */
  handleDocumentClick(event) {
    if (this.dropdownVisible && !this.dropdownContainerTarget.contains(event.target) && !this.handoffTarget.contains(event.target)) {
      this.toggleSearchDropdown();
    }
  }

  /**
   * Event handlers for connected targets, navigating to their href on click
   */
  resultTargetConnected(element) {
    element.addEventListener('click', (event) => {
      event.preventDefault();
      window.location.href = element.href;
    });
  }

  pinnedResultTargetConnected(element) {
    element.addEventListener('click', (event) => {
      event.preventDefault();
      window.location.href = element.href;
    });
  }

  myTagsTargetConnected(element) {
    element.addEventListener('click', (event) => {
      event.preventDefault();
      window.location.href = element.href;
    });
  }

  advancedSearchTargetConnected(element) {
    element.addEventListener('click', (event) => {
      event.preventDefault();
      window.location.href = element.href;
    });
  }

  /**
   * Clean up event listeners for disconnected targets
   */
  resultTargetDisconnected(element) {
    element.removeEventListener('click', this.handleNavClick);
  }

  pinnedResultTargetDisconnected(element) {
    element.removeEventListener('click', this.handleNavClick);
  }

  myTagsTargetDisconnected(element) {
    element.removeEventListener('click', this.handleNavClick);
  }

  advancedSearchTargetDisconnected(element) {
    element.removeEventListener('click', this.handleNavClick);
  }

  /**
   * Handle the handoff feature, where clicking the magnifying glass menu item activates the new search and shows the
   * modal-like backdrop.
   * @param event
   */
  handleHandoff(event) {
    event.preventDefault();
    this.useBackdrop = true;
    this.toggleSearchDropdown();
  }

  /**
   * Show the backdrop for the handoff feature
   */
  showBackdrop() {
    const navBar = document.querySelector('#topbar');
    const searchForm = navBar.querySelector('form');
    searchForm.style.zIndex = '12';
    document.querySelector('body').appendChild(this.backdrop);
    setTimeout(() => this.backdrop.classList.add('show'), 10);
  }

  /**
   * Hide the backdrop for the handoff feature
   */
  hideBackdrop() {
    const backdrop = document.querySelector('.mag-backdrop');
    if (backdrop) {
      backdrop.classList.remove('show');
      setTimeout(() => backdrop.remove(), 300);
    }
  }

  /**
   * Navigate to the href of the clicked element
   * @param event
   */
  handleNavClick(event) {
    event.preventDefault();
    window.location.href = event.currentTarget.href;
  }

  /**
   * Handle keyboard shortcuts and navigation
   * @param event
   */
  handleKeyDown(event) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      this.toggleSearchDropdown();
    }

    if (event.key === 'Escape') {
      this.closeSearchDropdown();
    }

    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      this.navigateResults(event.key);
    }

    if (event.key === 'Enter' && document.activeElement === this.queryTarget) {
      event.preventDefault();
      const currentActive = this.resultsTarget.querySelector('.active');

      if (document.activeElement === this.queryTarget && !currentActive) {
        return;
      }

      if (currentActive) {
        window.location.href = currentActive.href;
      }
    }
  }

  /**
   * Navigate through search results using keyboard arrows
   * @param direction - Which arrow was pressed
   */
  navigateResults(direction) {
    const currentActive = this.resultsTarget.querySelector('.active');
    let nextActive;
    if (direction === 'ArrowDown') {
      nextActive = currentActive ? currentActive.nextElementSibling : this.resultTargets[0];
    } else if (direction === 'ArrowUp') {
      nextActive = currentActive ? currentActive.previousElementSibling : this.resultTargets[this.resultTargets.length - 1];
    }
    if (nextActive) {
      if (currentActive) {
        currentActive.classList.remove('active');
      }
      nextActive.classList.add('active');
      nextActive.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  /**
   * Toggle the visibility of the search dropdown, and focus/blur input
   */
  toggleSearchDropdown() {
    if (this.dropdownVisible) {
      $(this.dropdownContainerTarget).dropdown('hide');
      this.queryTarget.blur();
      $(this.dropdownContainerTarget).trigger('hidden.bs.dropdown');
    } else {
      $(this.dropdownContainerTarget).dropdown('show');
      this.queryTarget.focus();
      $(this.dropdownContainerTarget).trigger('shown.bs.dropdown');
    }
  }

  closeSearchDropdown() {
    $(this.dropdownTarget).dropdown('hide');
    this.queryTarget.blur();
  }

  setInputPlaceholder() {
    this.queryTarget.placeholder = this.isMac ? 'Search Users (Cmd + k)' : 'Search Users (Ctrl + k)';
  }

  fetchResults() {
    const trimmedQuery = this.query.slice(0, 40);

    if (trimmedQuery === '') {
      this.reset();
      return;
    }

    if (trimmedQuery === this.previousQuery) {
      return;
    }
    this.previousQuery = trimmedQuery;

    const url = new URL(this.urlValue);
    url.searchParams.append('query', trimmedQuery);
    this.abortPreviousFetchRequest();

    this.abortController = new AbortController();
    fetch(url, {signal: this.abortController.signal})
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
      })
      .catch(() => {});
  }

  reset() {
    this.resultsTarget.innerHTML = ""
    this.queryTarget.value = ""
    this.previousQuery = null
  }

  abortPreviousFetchRequest() {
    if(this.abortController) {
      this.abortController.abort()
    }
  }

  get query() {
    return this.queryTarget.value
  }

  get searchResultsController() {
    return this.application.getControllerForElementAndIdentifier(this.resultsTarget.firstElementChild, "search-results")
  }

  /**
   * Create the modal-like backdrop element
   */
  createBackdrop() {
    this.backdrop = document.createElement('div');
    this.backdrop.style.cssText = 'z-index: 11; position: fixed; background-color: rgba(0,0,0,.15); top: 0; left: 0; width: 100%; height: 100%;';
    this.backdrop.classList.add('mag-backdrop');
  }
}

/**
 * Creates a debounce function that delays invoking 'func' until after 'wait' ms
 * have elapsed since the last time time function was invoked.
 *
 * @param func - The function to debounce.
 * @param wait - The number of milliseconds to delay.
 * @returns {(function(...[*]): void)|*} - A new debounced function.
 */
function debounce(func, wait) {
  let timeout;
  return function(...args) {
    const context = this;
    // Clear any existing timeout, canceling any previous function call
    clearTimeout(timeout);
    // Set a new timeout to call the function after the specified wait time
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
