import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'inputField'];

  toggleCampusLogin() {
    const trigger = this.triggerTarget;
    const inputField = this.inputFieldTarget;

    trigger.style.display = 'none';
    inputField.style.display = 'flex';

    inputField.querySelector('.form-control').focus();
  }
}