import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"
import { Chart } from 'chart.js';
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud';

export default class extends Controller {
  static targets = [  ]

  connect() {
    Chart.register(WordCloudController, WordElement);
    var data = JSON.parse(this.data.get('words'));
    Object.keys(data).map(function(key, index) {
      data[key] = 10 + data[key] * 10;
    });
    const chart = new Chart(document.getElementById(this.data.get('id')).getContext("2d"), {
      type: "wordCloud",
      data: {
        labels: Object.keys(data),
        datasets: [
          {
            label: "",
            data: Object.values(data)
          }
        ]
      },
      options: {
        title: {
          display: false,
          text: "Chart.js Word Cloud"
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }
}
