import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'source', 'dependent' ];
  static values = {
    unlock: String
  };

  connect() {
    this.sourceTarget.addEventListener('change', (e) => {
      // Checking if the value of the event's target (source element) is equal to the 'unlock' value. If the condition
      // matches the `unlock` value, enable the 'dependent' target. Otherwise, uncheck and disable the 'dependent'
      // target.
      if (e.target.value === this.unlockValue) {
        this.dependentTarget.disabled = false;
      } else {
        this.dependentTarget.checked = false;
        this.dependentTarget.disabled = true;
      }
    });
  }
}