import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { French } from 'flatpickr/dist/l10n/fr.js'

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/themes/airbnb.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      inline: true,
      minDate: 'today'
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    $('#availableSlots').empty();
    $('#linkLoadingSpinner').show();
    var slug = this.data.get('slug');
    var selectedReason = $('#appointment_appointment_reason_id').val();
    var selectedDate = dateStr;
    $('#schedule_appointment_start').val(selectedDate);
    this.queryTimeSlots(slug, selectedDate, selectedReason);
  }

  queryTimeSlots(slug, selectedDate, selectedReason) {
    fetch(`/scheduling_links/${slug}/schedule_time_slots.json?date=${selectedDate}&appointment_reason_id=${selectedReason}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((data) => {
      $('.alert.alert-light.instruction-reminder').hide();
      $('#linkLoadingSpinner').hide();
      $('#start_time, #end_time').val('');
      $('#confirmStudentSchedule').hide();
      if (data.length > 0) {
        $('.no-times').hide();
        $.each(data, function (i, val){
          $('#availableSlots').append(
            `<button type="button" class="link-schedule-slot-option btn btn-outline-primary m-2" data-start='${val.start}' data-end='${val.end}' data-date='${val.dateDesc}'>${val.start}</button>`
          );
        });
        var location_preference = data[0]['location_preference'];
        if (location_preference == 'both') {
          $('.confirmStudentScheduleMessage').text('You have the option to choose how you would like to meet. Please select an option below:');
          $('#confirmStudentScheduleLocationChoice').show();
        } else if (location_preference == 'in_person') {
          $('.confirmStudentScheduleMessage').text(`This appointment will meet In-Person`);
          $('#confirmStudentScheduleLocationChoice').hide();
          $('#student_location_preference option[value=in_person').attr('selected', 'selected');
          $('#student_location_preference option[value=remote').attr('selected', false);
        } else {
          $('.confirmStudentScheduleMessage').text(`This appointment will meet online via Zoom`);
          $('#confirmStudentScheduleLocationChoice').hide();
          $('#student_location_preference option[value=remote').attr('selected', 'selected');
          $('#student_location_preference option[value=in_person').attr('selected', false);
        };
      } else {
        $('.no-times').show();
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
}
