import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]
  static values = {
    div: String,
    reverse: { type: Boolean, default: false },
  }

  connect() {
  }

  toggle() {
    const elem = document.getElementById(this.divValue);
    if (this.reverseValue) {
      if (this.checkboxTarget.checked) {
        elem.style.display = 'none';
      } else {
        elem.style.display = '';
      }
    } else {
      if (this.checkboxTarget.checked) {
        elem.style.display = '';
      } else {
        elem.style.display = 'none';
      }
    };

  }
}
