// noinspection JSUnresolvedReference

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'trixText' ]

  connect() {
    const trixFileTools = this.trixTextTarget.previousSibling.querySelector('.trix-button-group--file-tools');

    trixFileTools.style.display = 'none';
  }

  disableAttachment(event) {
    event.preventDefault();
  }
}