import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ["trigger"]
  static values = { url: String }

  connect() {
    this.triggerWidth = this.triggerTarget.offsetWidth;
    this.triggerTarget.style.minWidth = this.triggerWidth + 'px';
  }

  updateButtonText() {
    this.triggerTarget.innerHTML = `
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      Syncing...
    `;
  }
}
