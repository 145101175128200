// noinspection JSUnresolvedReference

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'conditional', 'dependent' ];
  static values = { unlock: String };

  connect() {
    this.setInitialState();
  }

  setInitialState() {
    this.disableDependent();
  }

  disableDependent() {
    this.dependentTarget.disabled = true;
  }

  enableDependent() {
    this.dependentTarget.disabled = false;
  }

  conditionalChanged(event) {
    const conditionalField = this.conditionalTarget;
    let isUnlockConditionMet = false;

    switch (conditionalField.type) {
      case 'checkbox':
        isUnlockConditionMet = conditionalField.checked.toString() === this.unlockValue;
        break;
      case 'select-one':
      case 'select-multiple':
      case 'text':
        isUnlockConditionMet = conditionalField.value === this.unlockValue;
        break;
      default:
        console.warn('Unsupported conditional field type', conditionalField.type);
        break;
    }

    if (isUnlockConditionMet) {
      this.enableDependent();
    } else {
      this.disableDependent();
    }
  }
}
