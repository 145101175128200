import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['dtime']
  static values = {
    region: String
  }

  connect() {
    this.initDateTimeSelector();
  }

  initDateTimeSelector() {
    const dateFormat = this.regionValue === 'na' ? 'm-d-Y h:i K' : 'd-m-Y h:i K';
    return flatpickr(this.dtimeTarget, {
      enableTime: true,
      dateFormat: 'Z',
      altInput: true,
      altFormat: dateFormat,
      maxDate: new Date
    })
  }
}