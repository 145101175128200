import { Controller } from '@hotwired/stimulus'
import flatpickr from "flatpickr";

/**
 * Usage:
 * Specify either a 'date' or 'time' value, and add the 'field' target to the input field
 *
 * <div class="form-group" data-controller="scheduling-input" data-scheduling-input-render-value="date">
 *   <%= f.text_field :scheduled_start, placeholder: 'Select Date', class: 'form-control', data: { scheduling_input_target: 'field' } %>
 * </div>
 */

export default class extends Controller {
  static targets = ['field']
  static values = {
    render: { type: String, default: 'date' } // use either 'date' or 'time'
  }
  connect() {
    if (this.renderValue === 'date') {
      this.initDateSelector();
    }
    if (this.renderValue === 'time') {
      this.initTimeSelector();
    }
  }

  initDateSelector() {
    return flatpickr(this.fieldTarget, {
      altInput: true,
      altFormat: 'F j, Y',
      dateFormat: 'Y-m-d'
    });
  }

  initTimeSelector() {
    return flatpickr(this.fieldTarget, {
      enableTime: true,
      noCalendar: true,
      time_24hr: false,
      dateFormat: 'h:i K',
      altInput: true,
      altFormat: 'G:i K'
    });
  }
}