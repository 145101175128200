// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import * as Toastr from 'toastr';
import 'toastr/build/toastr.css';

export default class extends Controller {
  static targets = [ "links", "item", "edit", "taskList", "taskItem", "name", "dueDate", "editField", "details" ]

  connect() {
  }

  markComplete(event) {
    let taskId = this.data.get("id")
    if (event.currentTarget.checked == true) {
      event.currentTarget.parentElement.parentElement.nextElementSibling.classList.add("dashed")
      event.currentTarget.parentElement.parentElement.parentElement.parentElement.classList.add("task-completed")
      event.currentTarget.parentElement.parentElement.nextElementSibling.classList.remove("not-dashed")
      this.updateTask(taskId, true, true)
    } else {
      event.currentTarget.parentElement.parentElement.nextElementSibling.classList.remove("dashed")
      event.currentTarget.parentElement.parentElement.parentElement.parentElement.classList.remove("task-completed")
      event.currentTarget.parentElement.parentElement.nextElementSibling.classList.add("not-dashed")
      this.updateTask(taskId, false, true)
    }
  }

  updateTask(id, complete, verify) {
    fetch(`/tasks/${id}/toggle`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({verify: verify}),
    })
    .then((response) => response)
    .then((data) => {
      Toastr.success('Task updated!');
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
  }

  onPostDeleteSuccess(event) {
    let [data, status, xhr] = event.detail;
  }

  toggleDetails(event) {
    if (event.currentTarget.parentElement.parentElement.parentElement.nextElementSibling.style.display == 'none' ){
      event.currentTarget.parentElement.parentElement.parentElement.nextElementSibling.style.display = 'block';
    } else {
      event.currentTarget.parentElement.parentElement.parentElement.nextElementSibling.style.display = 'none';
    };
  }
}
