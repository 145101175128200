import { Controller } from "@hotwired/stimulus"
import * as Toastr from 'toastr';
import 'toastr/build/toastr.css';

export default class extends Controller {
  static targets = [ "name", "dueDate" ]

  onPostSuccess(event) {
    if (event.detail.success) {
      $('#modal-new-task-window').modal('hide');
      Toastr.success('Task created!');
      this.nameTarget.value = "";
      this.dueDateTarget.value = "";
      // TODO: replace this reload with turbo frame to avoid refresh
      location.reload();
    }
  }
}
