// noinspection JSUnresolvedReference

import { Controller } from '@hotwired/stimulus'
import UrlParameterMixin from "../mixins/url_parameter_mixin";

export default class AppointmentScheduleController extends Controller {
  connect() {
    // Get the appointment schedule id from the URL
    const appointmentScheduleId = this.getParameterByName("appointment_schedule_id");
    // Get the expected group size after user addition/deletion
    const pendingSize = this.getParameterByName('pending_size');

    if (appointmentScheduleId) {
      const attendeeCountElement = document.getElementById(`appointment_schedule_${appointmentScheduleId}`);

      if (attendeeCountElement) {
        attendeeCountElement.innerText = pendingSize;
        this.#redirectToCleanUrl();
      }
    }
  }

  // Private method to remove _added and _deleted parameters; otherwise refreshing the page will continue to
  // increment/decrement the updated value as long as these parameters are present
  #redirectToCleanUrl() {
    const url = new URL(window.location.href);

    // These params are no longer needed.
    url.searchParams.delete('pending_size');
    url.searchParams.delete('appointment_schedule_id');

    // Update the URL without reloading the page
    window.history.replaceState(null, null, url.href);
  }
}

// Mixin the methods from UrlParameterMixin into this controller
Object.assign(AppointmentScheduleController.prototype, UrlParameterMixin);