import { Controller } from "@hotwired/stimulus"
import SignaturePad from 'signature_pad/dist/signature_pad.min.js';

export default class extends Controller {
  // Define targets that can be accessed within the controller
  static targets = [ "name", "categories","result", "waiting", "message", "form", "clear" ]

  connect() {
    // Create an observer to monitor changes in provided element sizes
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.resizeCanvas();
      }
    });

    // Initialize a new SignaturePad instance on the element with the ID 'signature-pad'
    this.signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: 'rgb(0, 0, 0)',
      onEnd: function (event) {
        // When the drawing ends, set the value of the element with the ID 'signature_input' to the data URL of the signature
        $('#signature_input').val(this.toDataURL())
      }
    });
    // Add event listeners to resize the canvas when the window loads or is resized
    window.addEventListener('load', () => this.resizeCanvas());
    window.addEventListener('resize', () => this.resizeCanvas());
    // 'resize' is only supported on 'window' objects, hence the ResizeObserver
    this.resizeObserver.observe(this.signaturePad.canvas.parentElement);
  }

  disconnect() {
    // Remove the resize observer on Stimulus disconnect to prevent memory leaks and to ensure the observer is not
    // triggered when the controller is no longer active.
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }

  clear() {
    this.signaturePad.clear();
  }

  // Method to resize the canvas to match the width of its container
  resizeCanvas() {
    const canvas = this.signaturePad.canvas; // Get the canvas element from the signaturePad instance
    const container = canvas.parentNode; // Get the container element of the canvas

    // Set the canvas width to match the container's width
    canvas.width = container.offsetWidth;
  }
}
