import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["star", "input", "notapplicable"];
  static classes = ["hover"];

  enter(event) {
    this._fillToStar(event.params.starIndex);
  }

  leave() {
    if (this.inputTarget.value) {
      this._fillToStar(this.inputTarget.value - 1);
    } else {
      this._fillToStar(-1);
    }
  }

  click(event) {
    // If not applicable was chosen
    if (event.params.starIndex > -1) {
      this._fillToStar(event.params.starIndex);
      if (this.hasNotapplicableTarget) {
        this.notapplicableTarget.checked = false;
      }
    } else {
      this._fillToStar(event.params.starIndex);
    }
    this.inputTarget.setAttribute('value', event.params.starIndex + 1);
  }

  _fillToStar(star) {
     this.starTargets.forEach((target, index) => {
      if (index <= star) {
        target.classList.add(this.hoverClass);
      } else {
        target.classList.remove(this.hoverClass);
      }
    });
  }
}
