// noinspection JSUnresolvedReference

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'list' ]

  buildModal({ detail: { content } }) {
    const ids = content.ids;
    const modal = document.querySelector(content.dataSet.target);
    const hiddenField = modal.querySelector(`#${this.listTarget.id}`);
    hiddenField.value = ids;
  }
}