const UrlParameterMixin = {
  getParameterByName(name) {
    // Escape regex special characters in the parameter name
    name = name.replace(/[\[\]]/g, "\\$&");

    // Create a regex to match the parameter in the URL
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(window.location.href);

    // If no match is found, return null
    if (!results) return null;
    // If the parameter is present but has no value, return an empty string
    if (!results[2]) return '';

    // Decode and return the parameter value
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}

export default UrlParameterMixin;